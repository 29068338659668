import { useOneSignal } from "@onesignal/onesignal-vue3"
import type { ToastServiceMethods } from "primevue/toastservice"
import type { RouteLocationRaw } from "vue-router"
import * as Sentry from '@sentry/vue'

export function useAuth() {
  const account = useState<Account|null>('auth_account', () => null)
  const facebookAccount = useState<fb.AuthResponse|null>('auth_facebook', () => null)
  const googleAccount = useState<string|null>('auth_google', () => null)
  const isAuthenticated = computed(() => !!account.value)
  const refreshToken = useCookie<string>('auth_refresh')
  const token = useCookie<string>('auth_token')

  const doRefresh = async () : Promise<string> => {
    const config = useRuntimeConfig()

    const refresh = await $fetch<RefreshResponse>('/token/refresh/', {
      baseURL: config.public.baseURL,
      method: 'POST',
      body: JSON.stringify({
        refreshToken: refreshToken.value
      })
    })

    return refresh.access
  }

  const getAccount = async (useToken?: string): Promise<Account> => {
    const { $api } = useNuxtApp()
    return await $api<Account>('/account/', {
      headers: {
        Authorization: `Bearer ${useToken || token.value}`
      }
    })
  }

  const logout = async (to?: RouteLocationRaw) => {
    if (account.value?.facebook_user_id && facebookAccount.value) {
      FB.logout()
    }

    const oneSignal = useOneSignal()
    await oneSignal.logout()
      .catch(err => {
        console.error(err)
      })

    Sentry.setUser(null)

    google.accounts.id.disableAutoSelect()

    token.value = ""
    refreshToken.value = ""
    account.value = null

    await navigateTo(to ?? { name: 'account-login'})
  }

  const loginFacebook = (authType: "reauthenticate" | "reauthorize" | "rerequest" | undefined, toast: ToastServiceMethods) => {
    FB.login(
      (response) => onLoginFacebook(response, toast),
      {
        auth_type: authType,
        config_id: '277682762050564',
      }
    )
  }

  const onLoginFacebook = async (response: fb.StatusResponse, toast?: ToastServiceMethods) => {
    if (response.status !== 'connected') {
      toast?.add({
        detail: 'Unable to login with Facebook',
        life: 3000,
        severity: 'error',
        summary: 'Error',
      })
      return
    }

    facebookAccount.value = response.authResponse

    const { $api } = useNuxtApp()

    if (account.value) {  // User is logged in
      if (account.value.facebook_user_id) {
        toast?.add({
          detail: 'Your account is already linked with Facebook',
          life: 3000,
          severity: 'warn',
          summary: 'Facebook Account Already Linked',
        })
        return
      }

      await $api<Account>('/account/', {
        method: 'PATCH',
        body: JSON.stringify({
          facebook_user_id: response.authResponse.userID,
          facebook_access_token: response.authResponse.accessToken,
        })
      })
        .then(response => account.value = response)
        .catch(error => errorsToToast(error, toast))
    } else {  // User is not logged in
      await $api<LoginResponse>('/oauth/facebook/', {
        method: 'POST',
        body: JSON.stringify({
          user_id: response.authResponse.userID,
          access_token: response.authResponse.accessToken,
        })
      })
        .then(async (response) => await setTokens(response))
        .catch(error => errorsToToast(error, toast))
    }
  }

  const loginFacebookBusiness = (authType: "reauthenticate" | "reauthorize" | "rerequest" | undefined, callback: (response: fb.StatusResponse) => void) => {
    FB.login(
      function(response) {
        callback(response)
      },
      {
        auth_type: authType,
        config_id: '1108639717111646',
        response_type: 'code',
        override_default_response_type: true,
      }
    )
  }

  const loginGoogle = () => {
    google.accounts.id.prompt()
  }

  const onLoginGoogle = async (response: google.accounts.id.CredentialResponse, toast: ToastServiceMethods) => {
    if (!response.credential) {
      toast.add({
        detail: 'Unable to login with Google',
        life: 3000,
        severity: 'error',
        summary: 'Error',
      })
      return
    }

    const { $api } = useNuxtApp()

    if (account.value) {  // User is logged in
      if (account.value.google_user_id) {
        toast.add({
          detail: 'Your account is already linked with Google',
          life: 3000,
          severity: 'warn',
          summary: 'Google Account Already Linked',
        })
        return
      }

      await $api<Account>('/account/', {
        method: 'PATCH',
        body: JSON.stringify({
          google_user_id: response.credential,
        })
      })
        .then(response => account.value = response)
        .catch(error => errorsToToast(error, toast))
    } else {  // User is not logged in
      await $api<LoginResponse>('/oauth/google/', {
        method: 'POST',
        body: JSON.stringify({
          credential: response.credential
        })
      })
        .then(async (response) => await setTokens(response))
        .catch(error => {
          errorsToToast(error, toast)
          google.accounts.id.disableAutoSelect()
        })
    }
  }

  const setTokens = async (tokens: LoginResponse|null) => {
    if (!tokens) {
      throw createError({
        statusCode: 403,
        message: 'Unauthorized'
      })
    }

    token.value = tokens.access
    refreshToken.value = tokens.refresh

    try {
      account.value = await getAccount(tokens.access)
    } catch (e) {
      await logout({ name: 'account-login' })
    }

    const { query } = useRoute()
    if (query.redirect) {
      navigateTo(query.redirect as string)
      return
    }

    navigateTo('/')
  }

  return {
    account,
    doRefresh,
    facebookAccount,
    getAccount,
    googleAccount,
    isAuthenticated,
    loginFacebook,
    loginFacebookBusiness,
    loginGoogle,
    logout,
    onLoginFacebook,
    onLoginGoogle,
    refreshToken,
    setTokens,
    token,
  }
}
